import React, { useState, useRef } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import get from 'lodash/get';

import './PilotPlan.scss';

export const PilotPlan = ({ heading, text, call }) => {
    const [showDialog, setShowDialog] = useState(false);
    function open() {
        if (document.readyState == 'complete') {
            setShowDialog(true);
            var prefillData = {};
            hbspt.forms.create({
            portalId: "3421908",
            formId: "17de1176-dc10-4555-b4f2-6ce67862cb57",
            target: ".hbspt-form-modal-container",
            onFormSubmit: function($form) {
                const formData = new FormData($form[0])
                prefillData = {
                firstName: formData.get('firstname'),
                lastName: formData.get('lastname'),
                email: formData.get('email'),
                company: formData.get('company'),
                jobTitle: formData.get('jobtitle'),
                phone: formData.get('phone')
                };
                window.document.querySelector('iframe.hs-form-iframe').style.display = 'none';
                Calendly.initInlineWidget({
                url: 'https://calendly.com/miralabs-sales/intro-meeting-with-mira?hide_event_type_details=1',
                parentElement: document.getElementById('calendlyScheduleWidgetPilotPlanCta'),
                prefill: {
                name: `${prefillData.firstName} ${prefillData.lastName}`,
                email: prefillData.email,
                customAnswers: {
                    a1: prefillData.company,
                    a2: prefillData.jobTitle,
                    }
                },
                utm: {}
                });
                window.document.querySelector('#calendlyScheduleWidgetPilotPlanCta').style.display = 'block';
            },
            });
        }
    }

    function close() {
        setShowDialog(false);
    }
    return (
      <React.Fragment>
      <section className="pilot-plan-section">
          <div className="container">
              <h1>{heading}</h1>
              <h2>{text}</h2>
              <a className="cta" onClick={open}>
                  {get(call, 'label')}
              </a>
          </div>
      </section>
      <DialogOverlay
                  isOpen={showDialog}
                  onDismiss={close}
                  css={{
                      position: 'fixed',
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0,0,0,0.9)',
                      // zIndex: 40,
                      zIndex: 2147483001,
                  }}
              >
                  <DialogContent aria-label="Form">
                      <button
                          className="close-button"
                          onClick={close}
                          css={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              border: '0 none',
                              backgroundColor: 'transparent',
                              padding: 20,
                              margin: 0,
                              color: '#FFF',
                              cursor: 'pointer',
                          }}
                      >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="28"
                              height="28"
                          >
                              <path
                                  css={{ fill: 'currentColor', strokeWidth: 0 }}
                                  d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"
                              ></path>
                          </svg>
                      </button>
                      <div
                          css={{
                              position: 'absolute',

                              top: 48,

                              bottom: 48,
                              background: '#fff',
                              padding: '2em 5em',
                              overflowY: 'scroll',

                          }}
                          className={'hbspt-form-modal-container'}
                      >
                      <div id="calendlyScheduleWidgetPilotPlanCta" className="calendly-inline-widget" data-url="https://calendly.com/miralabs-sales/intro-meeting-with-mira?hide_event_type_details=1" style={{minWidth:'320px',height:'630px',display:'none'}}></div>
                      <script
                          dangerouslySetInnerHTML={{
                            __html: `
                            Calendly.initInlineWidget({
                             url: 'https://calendly.com/miralabs-sales/intro-meeting-with-mira?hide_event_type_details=1',
                             parentElement: document.getElementById('calendlyScheduleWidgetPilotPlanCta'),
                             prefill: {},
                             utm: {}
                            });
                                `,
                          }}
                      />
                      </div>
                  </DialogContent>
              </DialogOverlay>
            </React.Fragment>
  );
};

export default PilotPlan;
