import React from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';

import composeClasses from 'lib/composeClasses';

import { useScrollContext } from 'components/ScrollProvider';
import { useNavigation } from 'components/Navigation';

import './NavigationItem.scss';

export const NavigationItem = ({ label, to = '/', target, style }) => (
    <Location>
        {({ location }) => (
            <li
                className={composeClasses(
                    'nav-item',
                    to === `${location.pathname}${location.hash}` && 'active',
                )}
                role="none presentation"
                style={style}
            >
                {target || to.match(/^((http(s)?:)?\/\/|mailto:).+/) ? (
                    <a
                        className="nav-link"
                        href={to}
                        target={target}
                        role="menuitem"
                    >
                        {label}
                    </a>
                ) : (
                    <Link className="nav-link" to={to} role="menuitem">
                        {label}
                    </Link>
                )}
            </li>
        )}
    </Location>
);

export const AnchorLink = ({ name, label, style }) => {
    const { anchors } = useNavigation();
    const { scrollTo } = useScrollContext();

    const active = anchors[name];

    function click(e) {
        e.preventDefault();

        if (active && active.anchorTop) {
            scrollTo(active.anchorTop);
        }
    }

    return (
        <li
            className={composeClasses(
                'nav-item',
                active && active.focal && 'active',
            )}
            role="none presentation"
            style={style}
        >
            <a
                className="nav-link anchor-link"
                href={`#${name}`}
                role="menuitem"
                onClick={click}
            >
                {label}
            </a>
        </li>
    );
};

export default NavigationItem;
