import React from 'react';

import NavigationLogo from 'components/NavigationLogo';
import NavigationItem from 'components/NavigationItem';

import './SiteFooter.scss';

export const SiteFooter = ({ links = [], social = {}, copyright }) => (
    <footer
        className="site-footer"
        itemScope
        itemType="http://schema.org/WPFooter"
        role="contentinfo"
    >
        <nav
            aria-label="Footer Menu"
            className="footer-menu container"
            role="navigation"
        >
            <NavigationLogo name="Mira Labs" />
            <h2 className="visuallyhidden">Footer Menu</h2>
            <ul
                className="nav"
                itemScope="itemScope"
                itemType="http://www.schema.org/SiteNavigationElement"
                role="menubar"
            >
                {links &&
                    links.map(({ label, href }, index) => (
                        <NavigationItem key={index} label={label} to={href} />
                    ))}
            </ul>
            <ul className="nav social-list" role="menubar">
                {['facebook', 'instagram', /*'twitter',*/ 'linkedin'].map(
                    key =>
                        social[key] && (
                            <a
                                key={key}
                                className={`social-item ${key}`}
                                href={social[key]}
                                rel="noopener noreferrer"
                                target="_blank"
                            ></a>
                        ),
                )}
            </ul>
        </nav>
        <span className="container copyright">{copyright}</span>
    </footer>
);

export default SiteFooter;
