import React from 'react';
import { motion } from 'framer-motion';

const container = {
    width: 12,
    height: 12,
};

export const PaginationBullet = ({ active = false, click }) => (
    <svg
        aria-hidden="true"
        role="img"
        viewBox="0 0 512 512"
        css={container}
        style={{ cursor: typeof click === 'function' ? 'pointer' : 'default' }}
    >
        <motion.circle
            cx="256"
            cy="256"
            r="128"
            fill="#B0B1B1"
            animate={{
                scale: active ? 2 : 1,
                fill: active ? '#4C9AF3' : '#B0B1B1',
            }}
            onClick={() => click && click()}
        />
    </svg>
);

export default PaginationBullet;
