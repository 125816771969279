import React, { Fragment, useState } from 'react';

import { useScrollContext } from 'components/ScrollProvider';

import NavigationLogo from '../NavigationLogo';
import NavigationItem, { AnchorLink } from '../NavigationItem';
import NavigationCallToAction from '../NavigationCallToAction';

import './StickyNavigation.scss';

function composeClasses(...args) {
    return args.filter(val => val).join(' ');
}

export const StickyNavigation = props => {
    const {
        sticky = false,
        pages = [],
        sections = [],
        loginLink,
        callToAction,
    } = props;

    const { top: scrollTop, direction } = useScrollContext();
    const [expanded, setExpanded] = useState(false);

    function close() {
        setExpanded(false);
    }

    return (
        <header
            className={composeClasses(
                'site-header',
                expanded && 'nav-expanded',
                sticky && scrollTop > 0 && 'nav-sticky',
                sticky && direction && `scroll-direction-${direction}`,
            )}
            itemScope
            itemType="http://schema.org/WPHeader"
            role="banner"
        >
            <nav
                aria-label="Main Menu"
                className="main-menu container"
                role="navigation"
            >
                <NavigationLogo name="Mira Labs" top={scrollTop === 0} />
                <h2 className="visuallyhidden">Main Menu</h2>
                <div className="main-nav nav">
                    <ul
                        className={composeClasses(
                            'nav-group',
                            (scrollTop === 0 || direction === 'up') &&
                                'visible',
                                'visible' // always visible
                        )}
                        itemScope="itemScope"
                        itemType="http://www.schema.org/SiteNavigationElement"
                        role="menubar"
                    >
                        {pages.map(({ label, href }) => (
                            <NavigationItem
                                key={href}
                                label={label}
                                to={href}
                            />
                        ))}
                    </ul>
                    {/*<ul
                        className={composeClasses(
                            'nav-group',
                            !(scrollTop === 0 || direction === 'up') &&
                                'visible',
                        )}
                        itemScope="itemScope"
                        itemType="http://www.schema.org/SiteNavigationElement"
                        role="menubar"
                    >
                        {sections.map(({ label, slug }) => (
                            <AnchorLink key={slug} label={label} name={slug} />
                        ))}
                    </ul>*/}
                </div>
                <ul
                    className="secondary-nav nav"
                    itemScope="itemScope"
                    itemType="http://www.schema.org/SiteNavigationElement"
                    role="menubar"
                >
                    {loginLink && (
                        <NavigationItem
                            label={loginLink.label}
                            to={loginLink.href}
                            target="_blank"
                        />
                    )}
                    {callToAction && (
                        <NavigationCallToAction
                            label={callToAction.label}
                            href={callToAction.href}
                        />
                    )}
                </ul>
                <button
                    className="menu-toggle nav-icon"
                    onClick={() => setExpanded(!expanded)}
                >
                    <span></span>
                </button>
            </nav>
            <nav
                aria-label="Mobile Menu"
                className="mobile-menu container"
                role="navigation"
            >
                <h2 className="visuallyhidden">Mobile Menu</h2>
                <ul
                    className="mobile-main-nav"
                    itemScope="itemScope"
                    itemType="http://www.schema.org/SiteNavigationElement"
                    role="menubar"
                    onClick={close}
                >
                    {pages.map(({ label, href }) => (
                        <NavigationItem key={href} label={label} to={href} />
                    ))}
                </ul>
                <ul
                    className="mobile-secondary-nav"
                    itemScope="itemScope"
                    itemType="http://www.schema.org/SiteNavigationElement"
                    role="menubar"
                >
                    {loginLink && (
                        <NavigationItem
                            label={loginLink.label}
                            to={loginLink.href}
                            target="_blank"
                        />
                    )}
                    {callToAction && (
                        <NavigationCallToAction
                            label={callToAction.label}
                            href={callToAction.href}
                        />
                    )}
                </ul>
            </nav>
        </header>
    );
};

export default StickyNavigation;
