import React from 'react';
import { motion } from 'framer-motion';
import Media from 'react-media';

export const Logo = ({ compact = false }) => (
    <Media queries={{ mobile: '(max-width: 992px)' }}>
        {({ mobile }) => (
            <motion.svg
                width="200px"
                height="30px"
                viewBox="0 0 200 30"
                initial="expanded"
                animate={compact && !mobile ? 'compact' : 'expanded'}
                variants={{
                    expanded: { scale: 1 },
                    compact: { scale: 0.666 },
                }}
            >
                <defs>
                    <linearGradient
                        x1="49.993617%"
                        y1="-0.0527667984%"
                        x2="49.993617%"
                        y2="99.9339921%"
                        id="linearGradient-1"
                    >
                        <stop
                            stopColor="#FF7E43"
                            stopOpacity="0"
                            offset="0%"
                        ></stop>
                        <stop stopColor="#FF7E43" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient
                        x1="49.9182131%"
                        y1="3.98893281%"
                        x2="49.9182131%"
                        y2="97.1049407%"
                        id="linearGradient-2"
                    >
                        <stop stopColor="#006AD2" offset="0%"></stop>
                        <stop
                            stopColor="#006AD2"
                            stopOpacity="0"
                            offset="100%"
                        ></stop>
                    </linearGradient>
                    <linearGradient
                        x1="50.026938%"
                        y1="-0.0527667984%"
                        x2="50.026938%"
                        y2="99.9339921%"
                        id="linearGradient-3"
                    >
                        <stop
                            stopColor="#81D4F7"
                            stopOpacity="0"
                            offset="0%"
                        ></stop>
                        <stop stopColor="#81D4F7" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient
                        x1="30.9960481%"
                        y1="16.9448703%"
                        x2="69.1171821%"
                        y2="83.4482501%"
                        id="linearGradient-4"
                    >
                        <stop
                            stopColor="#BB29BB"
                            stopOpacity="0"
                            offset="0%"
                        ></stop>
                        <stop stopColor="#BB29BB" offset="99.97%"></stop>
                    </linearGradient>
                    <linearGradient
                        x1="50.026938%"
                        y1="99.9339921%"
                        x2="50.026938%"
                        y2="-0.0527667984%"
                        id="linearGradient-5"
                    >
                        <stop
                            stopColor="#2D9AFA"
                            stopOpacity="0"
                            offset="0%"
                        ></stop>
                        <stop
                            stopColor="#2D9AFA"
                            stopOpacity="0.8"
                            offset="49.82%"
                        ></stop>
                        <stop stopColor="#2D9AFA" offset="93.57%"></stop>
                    </linearGradient>
                    <linearGradient
                        x1="31.0919244%"
                        y1="82.978427%"
                        x2="69.2130584%"
                        y2="16.4742967%"
                        id="linearGradient-6"
                    >
                        <stop stopColor="#D708B2" offset="0%"></stop>
                        <stop
                            stopColor="#D708B2"
                            stopOpacity="0"
                            offset="100%"
                        ></stop>
                    </linearGradient>
                    <linearGradient
                        x1="50.0140893%"
                        y1="95.8922925%"
                        x2="50.0140893%"
                        y2="2.77628458%"
                        id="linearGradient-7"
                    >
                        <stop
                            stopColor="#ED1861"
                            stopOpacity="0"
                            offset="0%"
                        ></stop>
                        <stop
                            stopColor="#ED1861"
                            stopOpacity="0.8"
                            offset="49.82%"
                        ></stop>
                        <stop stopColor="#ED1861" offset="93.57%"></stop>
                    </linearGradient>
                </defs>
                <g
                    id="Desktop"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="00_StickyNav_ScrollUp"
                        transform="translate(-100.000000, -23.000000)"
                        fillRule="nonzero"
                    >
                        <g
                            id="Mira-Logo_Horizontal_Dark_RGB-Copy"
                            transform="translate(100.000000, 23.000000)"
                        >
                            <motion.g
                                id="Group"
                                transform="translate(86.343612, 0.000000)"
                                fill="#FFFFFF"
                                initial="expanded"
                                animate={
                                    compact && !mobile ? 'compact' : 'expanded'
                                }
                                variants={{
                                    expanded: { opacity: 1 },
                                    compact: { opacity: 0 },
                                }}
                            >
                                <path
                                    d="M97.6336091,0 C89.6499409,0 81.8880413,5.54739762 81.8880413,15.1998695 C81.8880413,25.2406592 88.8737509,29.6785773 95.3604814,29.6785773 C100.405716,29.6785773 102.789728,28.1253059 104.231224,25.7399249 L104.231224,26.2946647 C104.231224,27.7369881 105.395509,28.9019416 106.837004,28.9019416 L113.600946,28.9019416 C113.600946,28.9019416 113.600946,19.6377876 113.600946,16.4757709 C113.600946,13.3137543 112.991082,0 97.6336091,0 Z M97.6336091,21.9122206 C93.8081014,21.9122206 90.869668,19.2494697 90.869668,15.0334475 C90.869668,10.8174254 93.8081014,8.1546745 97.6336091,8.1546745 C101.514559,8.1546745 104.452992,10.8174254 104.452992,15.0334475 C104.452992,19.2494697 101.514559,21.9122206 97.6336091,21.9122206 Z"
                                    id="Shape"
                                ></path>
                                <path
                                    d="M53.6125497,28.9019416 L60.2101644,28.9019416 L60.2101644,3.55033448 C60.2101644,2.10801109 59.0458794,0.943057595 57.6043838,0.943057595 L51.0067691,0.943057595 L51.0067691,26.2946647 C50.951327,27.7369881 52.1156119,28.9019416 53.6125497,28.9019416 Z"
                                    id="Path"
                                ></path>
                                <path
                                    d="M72.5737617,6.0466634 L72.4628774,6.0466634 L72.4628774,3.55033448 C72.4628774,2.10801109 71.2985925,0.943057595 69.8570968,0.943057595 L63.4812507,0.943057595 L63.4812507,26.2946647 C63.4812507,27.7369881 64.6455356,28.9019416 66.0870313,28.9019416 L72.684646,28.9019416 L72.6292038,28.9019416 L72.684646,28.9019416 L72.684646,18.3618861 C72.684646,11.0393213 75.2349844,8.76488824 81.4999463,8.76488824 L81.4999463,0.0554739762 C77.2863436,0.166421929 74.1815838,1.55327133 72.5737617,6.0466634 Z"
                                    id="Path"
                                ></path>
                                <path
                                    d="M42.7458902,1.33137543 C41.2489524,0.499265786 39.4193618,0.0554739762 37.4234447,0.0554739762 C33.986032,0.0554739762 30.1050822,1.44232338 27.7210702,5.60287159 L27.6101859,5.60287159 C26.057806,1.88611519 22.509509,0.0554739762 18.9057699,0.0554739762 C15.2465886,0.0554739762 11.864618,1.1649535 10.1459117,6.10213738 L10.0904695,6.10213738 L10.0904695,3.60580845 C10.0904695,2.16348507 8.92618459,0.998531571 7.48468894,0.998531571 L0.277210702,0.998531571 L0.277210702,26.2946647 C0.277210702,27.7369881 1.44149565,28.9019416 2.8829913,28.9019416 L10.0904695,28.9019416 L10.0904695,12.0933268 C10.0904695,9.59699788 12.1418287,7.54446076 14.636725,7.54446076 C17.0207371,7.54446076 19.1829806,9.43057595 19.1829806,12.0933268 C19.1829806,13.979442 19.1829806,21.5239028 19.1829806,26.2946647 C19.1829806,27.7369881 20.3472655,28.9019416 21.7887611,28.9019416 L28.7744708,28.9019416 L28.7744708,12.0933268 C28.7744708,10.0407897 30.3268508,7.54446076 33.2652842,7.54446076 C36.2037176,7.54446076 37.8115397,9.92984174 37.8115397,12.0933268 L37.8115397,26.2391907 C37.8115397,27.6815141 38.9758246,28.8464676 40.4173203,28.8464676 L47.6247985,28.8464676 L47.6247985,10.2626856 C47.6802407,5.65834557 45.7952079,3.05106869 42.7458902,1.33137543 Z"
                                    id="Path"
                                ></path>
                            </motion.g>
                            <g
                                id="Group"
                                transform="translate(0.000000, 0.881057)"
                            >
                                <path
                                    d="M77.3657022,28.5716706 L65.819415,28.5716706 C61.9148734,28.5716706 60.2972776,22.8685191 58.1776693,16.3266689 C56.392736,10.8471705 53.7711153,0.279566249 48.5278737,0.279566249 L64.0344817,0.279566249 C70.1144107,0.279566249 77.3657022,28.5716706 77.3657022,28.5716706 Z"
                                    id="Path"
                                    fill="#CB0447"
                                ></path>
                                <path
                                    d="M77.3657022,28.5716706 L65.819415,28.5716706 C61.9148734,28.5716706 60.2972776,22.8685191 58.1776693,16.3266689 C56.392736,10.8471705 53.7711153,0.279566249 48.5278737,0.279566249 L64.0344817,0.279566249 C70.1144107,0.279566249 77.3657022,28.5716706 77.3657022,28.5716706 Z"
                                    id="Path"
                                    fill="url(#linearGradient-1)"
                                ></path>
                                <path
                                    d="M30.9574367,28.5716706 C26.6066618,28.5716706 24.4870535,21.4147747 22.3116661,14.3137919 L22.3116661,14.3137919 C20.1362786,7.26872247 18.0166704,0.279566249 13.7216746,0.279566249 L28.9493867,0.279566249 C33.41172,0.279566249 35.5871074,7.88376821 37.818274,15.2084039 C39.8821031,21.9739072 42.0017114,28.5157574 46.1293696,28.5716706 C46.1293696,28.5716706 46.1851488,28.5716706 46.1851488,28.5716706 L30.9574367,28.5716706 Z"
                                    id="Path"
                                    fill="url(#linearGradient-2)"
                                ></path>
                                <path
                                    d="M0,28.5716706 L11.5462872,28.5716706 C15.4508288,28.5716706 17.0684246,22.8685191 19.1880328,16.3266689 C20.917187,10.9030837 23.5945869,0.279566249 28.7820493,0.279566249 L13.3312205,0.279566249 C7.25129148,0.279566249 0,28.5716706 0,28.5716706 Z"
                                    id="Path"
                                    fill="url(#linearGradient-3)"
                                ></path>
                                <path
                                    d="M30.9574367,28.5716706 C26.6066618,28.5716706 24.4870535,21.4147747 22.3116661,14.3137919 L22.3116661,14.3137919 C20.1362786,7.26872247 18.0166704,0.279566249 13.7216746,0.279566249 L28.9493867,0.279566249 C33.41172,0.279566249 35.5871074,7.88376821 37.818274,15.2084039 C39.8821031,21.9739072 42.0017114,28.5157574 46.1293696,28.5716706 C46.1293696,28.5716706 46.1851488,28.5716706 46.1851488,28.5716706 L30.9574367,28.5716706 Z"
                                    id="Path"
                                    fill="url(#linearGradient-4)"
                                ></path>
                                <path
                                    d="M0,28.5716706 L11.5462872,28.5716706 C15.4508288,28.5716706 17.0684246,22.8685191 19.1880328,16.3266689 C20.917187,10.9030837 23.5945869,0.279566249 28.7820493,0.279566249 L13.3312205,0.279566249 C7.25129148,0.279566249 0,28.5716706 0,28.5716706 Z"
                                    id="Path"
                                    fill="url(#linearGradient-5)"
                                ></path>
                                <path
                                    d="M46.4082655,28.5716706 C50.7590403,28.5716706 52.8786486,21.4147747 55.0540361,14.3137919 L55.0540361,14.3137919 C57.1736443,7.26872247 59.3490318,0.279566249 63.6440275,0.279566249 L48.4163154,0.279566249 C43.9539822,0.279566249 41.7785947,7.88376821 39.5474281,15.2084039 C37.483599,21.9739072 35.3639907,28.4598441 31.2363325,28.5716706 C31.2363325,28.5716706 31.1805534,28.5716706 31.1805534,28.5716706 L46.4082655,28.5716706 Z"
                                    id="Path"
                                    fill="url(#linearGradient-6)"
                                ></path>
                                <path
                                    d="M46.4082655,28.5716706 C50.7590403,28.5716706 52.8786486,21.4147747 55.0540361,14.3137919 L55.0540361,14.3137919 C57.1736443,7.26872247 59.3490318,0.279566249 63.6440275,0.279566249 L48.4163154,0.279566249 C43.9539822,0.279566249 41.7785947,7.88376821 39.5474281,15.2084039 C37.483599,21.9739072 35.3639907,28.4598441 31.2363325,28.5716706 C31.2363325,28.5716706 31.1805534,28.5716706 31.1805534,28.5716706 L46.4082655,28.5716706 Z"
                                    id="Path"
                                    fill="url(#linearGradient-7)"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </motion.svg>
        )}
    </Media>
);

export default Logo;
