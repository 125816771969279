import React from 'react';
import { Link } from 'gatsby';

import Logo from 'components/Logo';

import './NavigationLogo.scss';

export const NavigationLogo = ({ name, top = true }) => (
    <h1 className="site-title">
        <Link className="site-link" to="/" title={name}>
            <Logo name={name} compact={!top} />
        </Link>
    </h1>
);

export default NavigationLogo;
