import React, {
    createContext,
    createRef,
    useState,
    useEffect,
    useContext,
} from 'react';

import { useScrollContext } from 'components/ScrollProvider';

const defaultContext = {};

export const NavigationContext = createContext(defaultContext);

export const NavigationProvider = ({ children }) => {
    const [anchors, setAnchors] = useState({});

    function updateAnchor(anchor, props) {
        setAnchors(current => ({ ...current, [anchor]: props }));
    }

    return (
        <NavigationContext.Provider value={{ updateAnchor, anchors }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => {
    return useContext(NavigationContext);
};

export const createAnchor = name => {
    const { updateAnchor } = useContext(NavigationContext);
    const { top: scrollTop } = useScrollContext();

    const ref = createRef();

    useEffect(() => {
        const { current } = ref;

        const mounted = !!current;
        let focal = false;
        let visible = false;
        let anchorTop;

        if (current) {
            const { top, bottom } = current.getBoundingClientRect();

            anchorTop = top + scrollTop;
            visible = top < window.innerHeight && bottom > 0;

            // The box is "focal" if the vertical center of the screen falls between its top and bottom.
            focal =
                top < window.innerHeight / 2 && bottom > window.innerHeight / 2;
        }

        updateAnchor(name, { visible, focal, mounted, anchorTop });
    }, [scrollTop]);

    return ref;
};
