import React, { createContext, useContext, useState, useEffect } from 'react';

const SCROLL_RATE = 15;

const defaultContext = { top: 0 };

export const ScrollContext = createContext(defaultContext);

export function easeInOutSine(elapsed, initialValue, amountOfChange, duration) {
    return (
        (-amountOfChange / 2) * (Math.cos((Math.PI * elapsed) / duration) - 1) +
        initialValue
    );
}

let duration;
let animating = false;
let startTime;
let startPosition;
let delta;

function tick() {
    if (!animating) return;

    const elapsed = Date.now() - startTime;

    const value = easeInOutSine(
        Math.min(elapsed, duration),
        startPosition,
        delta,
        duration,
    );
    window.scrollTo(0, Math.ceil(value));

    if (elapsed < duration) {
        requestAnimationFrame(tick);
    }
}

function scrollTo(position) {
    animating = true;
    startTime = Date.now();
    startPosition = window.scrollY;
    delta = position - startPosition;

    // Scroll at 50 frames per second.
    duration = 1000 * (Math.abs(delta) / (SCROLL_RATE * 50));

    requestAnimationFrame(tick);
}

function jumpTo(position) {
    window.scrollTo(0, Math.ceil(position));
}

export const ScrollProvider = ({ children }) => {
    const [top, setTop] = useState(0);
    const [direction, setDirection] = useState(undefined);

    useEffect(() => {
        let pending = false;
        function onScroll() {
            if (!pending) {
                window.requestAnimationFrame(() => {
                    if (top !== window.scrollY) {
                        setTop(window.scrollY);
                        setDirection(window.scrollY - top > 0 ? 'down' : 'up');
                    }

                    pending = false;
                });

                pending = true;
            }
        }

        window.addEventListener('scroll', onScroll);

        // Capture initial scroll point. Important if a page loads at an anchor tag.
        onScroll();

        return function dispose() {
            window.removeEventListener('scroll', onScroll);
        };
    });

    return (
        <ScrollContext.Provider value={{ top, direction, scrollTo, jumpTo }}>
            {children}
        </ScrollContext.Provider>
    );
};

export function useScrollContext() {
    return useContext(ScrollContext);
}

export default ScrollProvider;
